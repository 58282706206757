import React, { useEffect, useState } from "react";
import { fetchRecentChats } from "../../services/fetchRecentChats";
import "../RecentChats/recentChat.css";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

function RecentChats({ email, setSessionId, setConversation }) {
    const [recentChats, setRecentChats] = useState(null);
    const [selectedSessionId, setSelectedSessionId] = useState(null);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const isV2 = location.pathname.includes("/v2");
    const isV1 = location.pathname.includes("/v1");
    const version = isV2 ? "v2" : isV1 ? "v1" : "v3";

    useEffect(() => {
        if (email === "guest@user.com") {
            setRecentChats(null); // Reset recentChats when email is guest
            return;
        }
        setRecentChats(null); // Reset recentChats when email changes
        // const version = isV2 ? "v2" : "v1";

        setLoading(true);
        fetchRecentChats(email, version)
            .then(chats => {
                setRecentChats(chats);
                setLoading(false);
            })
            .catch(err => {
                console.error("Failed to fetch recent chats", err);
                setLoading(false);
            });
    }, [email, version]);

    const versionKey = isV2 ? "conversation_v2" : "conversation_v1";

    const handleChatClick = (sessionId) => {
        const selectedChatDetails = recentChats[sessionId];
        if (selectedChatDetails && selectedChatDetails.length > 0) {
            const sortedChatDetails = [...selectedChatDetails].sort((a, b) => a.id - b.id);

            const sessionDetails = sortedChatDetails[0]; // The first message in the sorted list

            setSelectedSessionId(sessionId);
            setSessionId(sessionDetails.session_id);
            setConversation(sortedChatDetails);  // Set the sorted conversation

            localStorage.setItem("sessionId", sessionDetails.session_id);
            localStorage.setItem(versionKey, JSON.stringify(sortedChatDetails));

            window.location.reload();
        }
    };

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    };

    const today = dayjs().startOf('day');
    const todayChats = [];
    const previousChats = [];

    if (recentChats) {
        Object.keys(recentChats).forEach(sessionId => {
            const chatDate = dayjs(recentChats[sessionId][0]?.created_at);
            if (chatDate.isAfter(today)) {
                todayChats.push({ sessionId, chat: recentChats[sessionId] });
            } else {
                previousChats.push({ sessionId, chat: recentChats[sessionId] });
            }
        });
    }
    todayChats.sort((a, b) => dayjs(b.chat[0].created_at).valueOf() - dayjs(a.chat[0].created_at).valueOf());
    previousChats.sort((a, b) => dayjs(b.chat[0].created_at).valueOf() - dayjs(a.chat[0].created_at).valueOf());


    if (loading) {
        return (
            <div className="loader">                
            </div>
        );
    }

    if (email === "guest@user.com") {
        return <div>Login to see recent chats</div>; // Message for guest user
    }

    if (recentChats === null) {
        return null; // Or show a loading indicator
    }

    if (Object.keys(recentChats).length === 0) {
        return <div>No chats present</div>;
    }

    return (
        <div className="recent-chats">
            <h5>Recent Chats</h5>
            {todayChats.length > 0 && (
                <>
                    <p className="today">Today</p>
                    <ul>
                        {todayChats.map(({ sessionId }) => (
                            <li key={sessionId} onClick={() => handleChatClick(sessionId)}
                            className={sessionId === selectedSessionId ? "active" : ""}>
                                {truncateText(recentChats[sessionId][0]?.prompt || "Untitled Chat", 100)}
                            </li>
                        ))}
                    </ul>
                </>
            )}
            {previousChats.length > 0 && (
                <>
                    <p className="previous">Previous Chats</p>                    
                    <ul>
                        {previousChats.map(({ sessionId }) => (
                            <li key={sessionId} onClick={() => handleChatClick(sessionId)}
                                className={sessionId === selectedSessionId ? "active" : ""}>
                                {truncateText(recentChats[sessionId][0]?.prompt || "Untitled Chat", 60)}
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
}

export default RecentChats;
